// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextExtractionStream_streamWrapper__VZYbu {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
}

.TextExtractionStream_streamContainer__DviRa {
    max-height: 70vh;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.TextExtractionStream_loadingMessage__fKZgg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
}

.TextExtractionStream_messageText__7jZid {
    font-size: 1.1em;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
    min-height: 1.5em;
    transition: opacity 0.3s ease-in-out;
}

.TextExtractionStream_progressAnimation__d3faz {
    width: 80%;
    height: 4px;
    background-color: #f0f0f0;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
}

.TextExtractionStream_progressBar__rgVWP {
    position: absolute;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, #3498db, transparent);
    animation: TextExtractionStream_progressAnimation__d3faz 2s linear infinite;
}

@keyframes TextExtractionStream_progressAnimation__d3faz {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/TextExtractionStream/TextExtractionStream.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,yCAAyC;IACzC,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,oCAAoC;AACxC;;AAEA;IACI,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,qEAAqE;IACrE,2EAA+C;AACnD;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".streamWrapper {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    max-width: 800px;\n    margin: 0 auto;\n    margin-top: 20px;\n}\n\n.streamContainer {\n    max-height: 70vh;\n    border: 1px solid #e0e0e0;\n    border-radius: 10px;\n    padding: 20px;\n    background-color: #ffffff;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    margin: 20px 0;\n}\n\n.loadingMessage {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 200px;\n}\n\n.messageText {\n    font-size: 1.1em;\n    color: #2c3e50;\n    margin-bottom: 20px;\n    text-align: center;\n    min-height: 1.5em;\n    transition: opacity 0.3s ease-in-out;\n}\n\n.progressAnimation {\n    width: 80%;\n    height: 4px;\n    background-color: #f0f0f0;\n    border-radius: 2px;\n    overflow: hidden;\n    position: relative;\n}\n\n.progressBar {\n    position: absolute;\n    left: -100%;\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(90deg, transparent, #3498db, transparent);\n    animation: progressAnimation 2s linear infinite;\n}\n\n@keyframes progressAnimation {\n    0% {\n        left: -100%;\n    }\n    100% {\n        left: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"streamWrapper": `TextExtractionStream_streamWrapper__VZYbu`,
	"streamContainer": `TextExtractionStream_streamContainer__DviRa`,
	"loadingMessage": `TextExtractionStream_loadingMessage__fKZgg`,
	"messageText": `TextExtractionStream_messageText__7jZid`,
	"progressAnimation": `TextExtractionStream_progressAnimation__d3faz`,
	"progressBar": `TextExtractionStream_progressBar__rgVWP`
};
export default ___CSS_LOADER_EXPORT___;
