// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
}

body {
    background-color: #f0f4f8; /* Fallback color */
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,yBAAyB,EAAE,mBAAmB;AAClD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["body, html {\n    margin: 0;\n    padding: 0;\n    font-family: Arial, sans-serif;\n    height: 100%;\n}\n\nbody {\n    background-color: #f0f4f8; /* Fallback color */\n}\n\n.app {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n}\n\n.main-content {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
