import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import DOMPurify from 'dompurify';
import styles from './ChatContainer.module.css';
import apiService from '../../services/api.service';
import { useChatContext } from '../../contexts/ChatContext';
import {useAuth} from "../../contexts/AuthContext";

interface Message {
    id: number;
    text: string;
    sender: 'user' | 'bot';
}

const ThinkingAnimation: React.FC = () => (
    <div className={styles.thinkingAnimation}>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
    </div>
);

const sanitizeHtml = (html: string): string => {
    return DOMPurify.sanitize(html, {
        ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'br'],
    });
};

const MessageContent: React.FC<{ content: string }> = ({ content }) => {
    const sanitizedHtml = sanitizeHtml(content);
    const htmlWithLineBreaks = sanitizedHtml.replace(/\n/g, '<br>');
    return <div dangerouslySetInnerHTML={{ __html: htmlWithLineBreaks }} />;
};

interface ChatContainerProps {
    onUploadError: (error: string) => void;
    initialMessage?: string;
}
interface SendMessageEvent extends CustomEvent {
    detail: {
        message: string;
    };
}


export const ChatContainer: React.FC<ChatContainerProps> = ({  onUploadError, initialMessage }) => {
    const { sessionId, useDirectGpt } = useChatContext();
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isThinking, setIsThinking] = useState(false);
    const [streamingMessage, setStreamingMessage] = useState<Message | null>(null);
    const messageListRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const { token } = useAuth();

    // Set initial message when component mounts
    useEffect(() => {
        if (initialMessage) {
            setMessages([{
                id: Date.now(),
                text: initialMessage,
                sender: 'bot'
            }]);
        }
    }, [initialMessage]);

    const scrollToBottom = () => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (streamingMessage) {
            scrollToBottom();
        }
    }, [streamingMessage?.text]);

    useLayoutEffect(() => {
        scrollToBottom();
    }, [messages, isThinking]);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);


    useEffect(() => {
        inputRef.current?.focus();

        // Add event listener for custom sendChatMessage event
        const handleSendMessage = async (e: Event) => {
            const customEvent = e as SendMessageEvent;
            const message = customEvent.detail.message;

            if (message && sessionId) {
                // Add user message to chat immediately
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        id: Date.now(),
                        text: message,
                        sender: 'user',
                    },
                ]);

                setIsThinking(true);
                let botResponseText = '';
                let hasReceivedFirstChunk = false;
                setStreamingMessage({ id: Date.now(), text: '', sender: 'bot' });

                try {
                    await apiService.streamChatResponse(
                        message,
                        sessionId,
                        useDirectGpt,
                        (chunk) => {
                            if (!hasReceivedFirstChunk) {
                                hasReceivedFirstChunk = true;
                                setIsThinking(false);
                            }

                            botResponseText += chunk;

                            setStreamingMessage((prevMessage) => ({
                                ...prevMessage!,
                                text: botResponseText,
                            }));
                        },
                        token
                    );

                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { id: Date.now(), text: botResponseText, sender: 'bot' },
                    ]);
                    setStreamingMessage(null);
                } catch (error) {
                    console.error('Error sending message:', error);
                    onUploadError("Сесията ви изтече! Моля рефрешнете страницата и влезте отново!");
                } finally {
                    setIsThinking(false);
                }
            }
        };

        window.addEventListener('sendChatMessage', handleSendMessage);

        return () => {
            window.removeEventListener('sendChatMessage', handleSendMessage);
        };
    }, [sessionId, useDirectGpt]);



    const handleSendMessage = async (e: React.FormEvent) => {
        e.preventDefault();
        if (inputMessage.trim() === '' || !sessionId) return;

        const userMessage: Message = {
            id: Date.now(),
            text: inputMessage,
            sender: 'user',
        };

        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInputMessage('');
        setIsThinking(true);

        let botResponseText = '';
        let hasReceivedFirstChunk = false;
        setStreamingMessage({ id: Date.now(), text: '', sender: 'bot' });

        try {
            await apiService.streamChatResponse(
                userMessage.text,
                sessionId,
                useDirectGpt,
                (chunk) => {
                    if (!hasReceivedFirstChunk) {
                        hasReceivedFirstChunk = true;
                        setIsThinking(false);
                    }

                    botResponseText += chunk;

                    setStreamingMessage((prevMessage) => ({
                        ...prevMessage!,
                        text: botResponseText,
                    }));
                },
                token
            );

            setMessages((prevMessages) => [
                ...prevMessages,
                { id: Date.now(), text: botResponseText, sender: 'bot' },
            ]);
            setStreamingMessage(null);
        } catch (error) {
            console.error('Error sending message:', error);
            onUploadError("Сесията ви изтече! Моля рефрешнете страницата и влезте отново!");
        } finally {
            setIsThinking(false);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage(e);
        }
    };

    return (
        <div className={styles.chatContainer}>
            <div ref={messageListRef} className={styles.messageList}>
                {messages.map((message) => (
                    <div
                        key={message.id}
                        className={`${styles.message} ${
                            message.sender === 'user'
                                ? styles.userMessage
                                : styles.botMessage
                        }`}
                    >
                        <MessageContent content={message.text} />
                    </div>
                ))}
                {streamingMessage && (
                    <div className={`${styles.message} ${styles.botMessage}`}>
                        <MessageContent content={streamingMessage.text} />
                    </div>
                )}
                {isThinking && (
                    <div
                        className={`${styles.message} ${styles.botMessage} ${styles.thinking}`}
                    >
                        <ThinkingAnimation />
                    </div>
                )}
            </div>
            <form className={styles.inputArea} onSubmit={handleSendMessage}>
                <textarea
                    ref={inputRef}
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Въведете вашето съобщение..."
                    className={styles.input}
                    rows={1}
                />
                <button
                    type="submit"
                    className={styles.sendButton}
                    disabled={inputMessage.trim() === ''}
                >
                    Изпрати
                </button>
            </form>
        </div>
    );
};

export default ChatContainer;