import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Message, NotificationState } from '../types';
import apiService from '../services/api.service';

interface ChatContextProps {
    messages: Message[];
    addMessage: (message: Message) => void;
    isLoading: boolean;
    setIsLoading: (loading: boolean) => void;
    notification: NotificationState | null;
    setNotification: (notification: NotificationState | null) => void;
    sessionId: string;
    setSessionId: (sessionId: string) => void;
    useDirectGpt: boolean;
    setUseDirectGpt: (useDirectGpt: boolean) => void;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState<NotificationState | null>(null);
    const [sessionId, setSessionId] = useState<string>('');
    const [useDirectGpt, setUseDirectGpt] = useState<boolean>(false);

    useEffect(() => {
        const fetchSessionId = async () => {
            try {
                //const id = await apiService.getSessionId();

            } catch (error) {
                console.error('Failed to fetch session ID:', error);
                setNotification({
                    message: 'Failed to initialize session. Please refresh the page.',
                    type: 'error'
                });
            }
        };

        fetchSessionId();
    }, []);

    const addMessage = (message: Message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    return (
        <ChatContext.Provider value={{
            messages,
            addMessage,
            isLoading,
            setIsLoading,
            notification,
            setNotification,
            sessionId,
            setSessionId,
            useDirectGpt,
            setUseDirectGpt
        }}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = () => {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChatContext must be used within a ChatProvider');
    }
    return context;
};