// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentScanner_scannerContainer__tIzBE {
    width: 150px;  /* Reduced from 200px */
    height: 195px; /* Reduced from 260px to maintain aspect ratio */
    position: relative;
    margin: 20px auto;
}

.DocumentScanner_document__Xp4eS {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 2px solid #3498db;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.DocumentScanner_scanLine__fuYmy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: rgba(52, 152, 219, 0.5);
    animation: DocumentScanner_scan__t\\+QRv 2s linear infinite;
}

.DocumentScanner_textLines__L4ejB {
    padding: 20px;
}

.DocumentScanner_textLine__nPwdr {
    height: 10px;
    background-color: #ecf0f1;
    margin-bottom: 15px;
    border-radius: 5px;
    animation: DocumentScanner_pulse__\\+qZCV 1.5s ease-in-out infinite;
}

.DocumentScanner_textLine__nPwdr:nth-child(odd) {
    width: 100%;
}

.DocumentScanner_textLine__nPwdr:nth-child(even) {
    width: 80%;
}

@keyframes DocumentScanner_scan__t\\+QRv {
    0% {
        top: 0;
    }
    100% {
        top: 100%;
    }
}

@keyframes DocumentScanner_pulse__\\+qZCV {
    0%, 100% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/DocumentScanner/DocumentScanner.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY,GAAG,uBAAuB;IACtC,aAAa,EAAE,gDAAgD;IAC/D,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,WAAW;IACX,yCAAyC;IACzC,0DAAkC;AACtC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,kEAA0C;AAC9C;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,MAAM;IACV;IACA;QACI,SAAS;IACb;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".scannerContainer {\n    width: 150px;  /* Reduced from 200px */\n    height: 195px; /* Reduced from 260px to maintain aspect ratio */\n    position: relative;\n    margin: 20px auto;\n}\n\n.document {\n    width: 100%;\n    height: 100%;\n    background-color: #fff;\n    border: 2px solid #3498db;\n    border-radius: 10px;\n    overflow: hidden;\n    position: relative;\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\n}\n\n.scanLine {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 4px;\n    background-color: rgba(52, 152, 219, 0.5);\n    animation: scan 2s linear infinite;\n}\n\n.textLines {\n    padding: 20px;\n}\n\n.textLine {\n    height: 10px;\n    background-color: #ecf0f1;\n    margin-bottom: 15px;\n    border-radius: 5px;\n    animation: pulse 1.5s ease-in-out infinite;\n}\n\n.textLine:nth-child(odd) {\n    width: 100%;\n}\n\n.textLine:nth-child(even) {\n    width: 80%;\n}\n\n@keyframes scan {\n    0% {\n        top: 0;\n    }\n    100% {\n        top: 100%;\n    }\n}\n\n@keyframes pulse {\n    0%, 100% {\n        opacity: 0.6;\n    }\n    50% {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scannerContainer": `DocumentScanner_scannerContainer__tIzBE`,
	"document": `DocumentScanner_document__Xp4eS`,
	"scanLine": `DocumentScanner_scanLine__fuYmy`,
	"scan": `DocumentScanner_scan__t+QRv`,
	"textLines": `DocumentScanner_textLines__L4ejB`,
	"textLine": `DocumentScanner_textLine__nPwdr`,
	"pulse": `DocumentScanner_pulse__+qZCV`
};
export default ___CSS_LOADER_EXPORT___;
