// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProcessingStatus_statusContainer__jq2rQ {
    margin-top: 20px;
    width: 100%;
}

.ProcessingStatus_statusBar__A3bxS {
    width: 100%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
}

.ProcessingStatus_statusFill__aha-x {
    height: 100%;
    background-color: #3498db;
    transition: width 0.3s ease-in-out;
}

.ProcessingStatus_statusFill__aha-x.ProcessingStatus_complete__l4Cfy {
    background-color: #2ecc71;
}

.ProcessingStatus_statusText__Nrf79 {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #333;
}`, "",{"version":3,"sources":["webpack://./src/components/ProcessingStatus/ProcessingStatus.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,kCAAkC;AACtC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf","sourcesContent":[".statusContainer {\n    margin-top: 20px;\n    width: 100%;\n}\n\n.statusBar {\n    width: 100%;\n    height: 20px;\n    background-color: #e0e0e0;\n    border-radius: 10px;\n    overflow: hidden;\n}\n\n.statusFill {\n    height: 100%;\n    background-color: #3498db;\n    transition: width 0.3s ease-in-out;\n}\n\n.statusFill.complete {\n    background-color: #2ecc71;\n}\n\n.statusText {\n    margin-top: 10px;\n    text-align: center;\n    font-size: 14px;\n    color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusContainer": `ProcessingStatus_statusContainer__jq2rQ`,
	"statusBar": `ProcessingStatus_statusBar__A3bxS`,
	"statusFill": `ProcessingStatus_statusFill__aha-x`,
	"complete": `ProcessingStatus_complete__l4Cfy`,
	"statusText": `ProcessingStatus_statusText__Nrf79`
};
export default ___CSS_LOADER_EXPORT___;
