// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_app__zr1wm {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App_mainContent__mD5IX {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.App_contentContainer__3gsZa {
  display: flex;
  width: 100%;
  height: 100%;
}

.App_pdfPreviewWrapper__YG0lP {
  flex: 0 0 60%;
  overflow: hidden;
  margin-top:  20px;
}

.App_chatWrapper__L2PGT {
  flex: 1 1;
  overflow: hidden;
}

.App_fadeEnter__ED\\+BA {
  opacity: 0;
}



.App_fadeEnterActive__zluhP {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.App_fadeExit__Tlg93 {
  opacity: 1;
}

.App_fadeExitActive__pcrdQ {
  opacity: 0;
  transition: opacity 300ms ease-in;
}`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,SAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;;;AAIA;EACE,UAAU;EACV,iCAAiC;AACnC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,iCAAiC;AACnC","sourcesContent":[".app {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n.mainContent {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n\n.contentContainer {\n  display: flex;\n  width: 100%;\n  height: 100%;\n}\n\n.pdfPreviewWrapper {\n  flex: 0 0 60%;\n  overflow: hidden;\n  margin-top:  20px;\n}\n\n.chatWrapper {\n  flex: 1;\n  overflow: hidden;\n}\n\n.fadeEnter {\n  opacity: 0;\n}\n\n\n\n.fadeEnterActive {\n  opacity: 1;\n  transition: opacity 300ms ease-in;\n}\n\n.fadeExit {\n  opacity: 1;\n}\n\n.fadeExitActive {\n  opacity: 0;\n  transition: opacity 300ms ease-in;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `App_app__zr1wm`,
	"mainContent": `App_mainContent__mD5IX`,
	"contentContainer": `App_contentContainer__3gsZa`,
	"pdfPreviewWrapper": `App_pdfPreviewWrapper__YG0lP`,
	"chatWrapper": `App_chatWrapper__L2PGT`,
	"fadeEnter": `App_fadeEnter__ED+BA`,
	"fadeEnterActive": `App_fadeEnterActive__zluhP`,
	"fadeExit": `App_fadeExit__Tlg93`,
	"fadeExitActive": `App_fadeExitActive__pcrdQ`
};
export default ___CSS_LOADER_EXPORT___;
