// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_notification__SfISU {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px 20px;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    max-width: 300px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    animation: Notification_slideIn__zyAur 0.5s ease-out;
}

.Notification_success__5\\+j\\+a {
    background-color: #2ecc71;
}

.Notification_error__IBuVF {
    background-color: #e74c3c;
}

.Notification_closeButton__L5RCu {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

@keyframes Notification_slideIn__zyAur {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/Notification.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,yCAAyC;IACzC,oDAAgC;AACpC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd;;AAEA;IACI;QACI,2BAA2B;QAC3B,UAAU;IACd;IACA;QACI,wBAAwB;QACxB,UAAU;IACd;AACJ","sourcesContent":[".notification {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    padding: 15px 20px;\n    border-radius: 4px;\n    color: white;\n    font-size: 16px;\n    max-width: 300px;\n    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);\n    animation: slideIn 0.5s ease-out;\n}\n\n.success {\n    background-color: #2ecc71;\n}\n\n.error {\n    background-color: #e74c3c;\n}\n\n.closeButton {\n    background: none;\n    border: none;\n    color: white;\n    font-size: 20px;\n    cursor: pointer;\n    position: absolute;\n    top: 5px;\n    right: 5px;\n}\n\n@keyframes slideIn {\n    from {\n        transform: translateX(100%);\n        opacity: 0;\n    }\n    to {\n        transform: translateX(0);\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `Notification_notification__SfISU`,
	"slideIn": `Notification_slideIn__zyAur`,
	"success": `Notification_success__5+j+a`,
	"error": `Notification_error__IBuVF`,
	"closeButton": `Notification_closeButton__L5RCu`
};
export default ___CSS_LOADER_EXPORT___;
