// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__kXlYa {
    background-color: #34495e;
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".footer {\n    background-color: #34495e;\n    color: white;\n    text-align: center;\n    padding: 1rem;\n    font-size: 0.8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__kXlYa`
};
export default ___CSS_LOADER_EXPORT___;
