// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_loginContainer__EG5ag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.LoginForm_loginForm__\\+koOR {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.LoginForm_loginForm__\\+koOR h2 {
    text-align: center;
    color: #3498db;
    margin-bottom: 1.5rem;
}

.LoginForm_input__aQRtt {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.LoginForm_input__aQRtt:focus {
    border-color: #3498db;
    outline: none;
}

.LoginForm_loginButton__b169T {
    width: 100%;
    padding: 0.75rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.LoginForm_loginButton__b169T:hover {
    background-color: #2980b9;
}

.LoginForm_error__012y\\+ {
    color: #e74c3c;
    text-align: center;
    padding: 0.5rem;
    background-color: #fde8e7;
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/LoginForm/LoginForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".loginContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n}\n\n.loginForm {\n    background: white;\n    padding: 2rem;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    max-width: 400px;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}\n\n.loginForm h2 {\n    text-align: center;\n    color: #3498db;\n    margin-bottom: 1.5rem;\n}\n\n.input {\n    width: 100%;\n    padding: 0.75rem;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    font-size: 1rem;\n}\n\n.input:focus {\n    border-color: #3498db;\n    outline: none;\n}\n\n.loginButton {\n    width: 100%;\n    padding: 0.75rem;\n    background-color: #3498db;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    font-size: 1rem;\n    cursor: pointer;\n    transition: background-color 0.2s;\n}\n\n.loginButton:hover {\n    background-color: #2980b9;\n}\n\n.error {\n    color: #e74c3c;\n    text-align: center;\n    padding: 0.5rem;\n    background-color: #fde8e7;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": `LoginForm_loginContainer__EG5ag`,
	"loginForm": `LoginForm_loginForm__+koOR`,
	"input": `LoginForm_input__aQRtt`,
	"loginButton": `LoginForm_loginButton__b169T`,
	"error": `LoginForm_error__012y+`
};
export default ___CSS_LOADER_EXPORT___;
