import axios from 'axios';
import {config} from "react-transition-group";
import {LoginResponse, UploadResponse} from "../types";

const API_BASE_URL = '/api';

const apiService = {
    login: async (username: string, password: string): Promise<LoginResponse> => {
        const response = await fetch(`${API_BASE_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            throw new Error('Login failed');
        }

        return response.json();
    },

    uploadFiles: async (token: string | null, files: File[]): Promise<UploadResponse> => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });

        if (!token) {
            throw new Error('No authentication token found');
        }

        try {
            const response = await fetch(`${API_BASE_URL}/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            return await response.json();
        } catch (error) {
            console.error('Error uploading files:', error);
            throw error;
        }
    },



sendMessage: async (message: string, useDirectGpt: boolean): Promise<string> => {
        try {
            const response = await axios.post(`${API_BASE_URL}/chatbot`, { query: message, useDirectGpt }, {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            return response.data.response;
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    },

    getProcessingStatus: async (): Promise<{ totalPages: number, processedPages: number }> => {
        try {
            const response = await axios.get(`${API_BASE_URL}/processing-status` , {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error getting processing status:', error);
            throw error;
        }
    },
    getSessionId: async (): Promise<string> => {
        try {
            const response = await axios.get(`${API_BASE_URL}/session`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            return "asdasd";
        } catch (error) {
            console.error('Error getting session ID:', error);
            throw error;
        }
    },

    streamChatResponse: async (
        message: string,
        sessionId: string,
        useDirectGpt: boolean,
        onChunk: (chunk: string) => void,
        token: string | null
    ): Promise<void> => {

        if (!token) {
            throw new Error('No authentication token found');
        }

        try {
            const response = await fetch(`${API_BASE_URL}/chatbot-stream`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ query: message, sessionId, useDirectGpt }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const reader = response.body?.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader!.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const lines = chunk.split('\n\n');

                for (const line of lines) {
                    if (line.startsWith('data:')) {
                        const data = line.slice(5).trim();
                        if (data === '[DONE]') {
                            return;
                        }
                        try {
                            const jsonData = JSON.parse(data);
                            onChunk(jsonData.content);
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error streaming chat response:', error);
            throw error;
        }
    },
};

export default apiService;
