// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__HXQOm {
    background-color: #3498db;
    color: white;
    padding: 1rem;
    text-align: center;
}

.Header_title__x3ga3 {
    margin: 0;
    font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,iBAAiB;AACrB","sourcesContent":[".header {\n    background-color: #3498db;\n    color: white;\n    padding: 1rem;\n    text-align: center;\n}\n\n.title {\n    margin: 0;\n    font-size: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__HXQOm`,
	"title": `Header_title__x3ga3`
};
export default ___CSS_LOADER_EXPORT___;
