// src/App.tsx

import React, { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ChatProvider } from './contexts/ChatContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import FileUpload from './components/FileUpload/FileUpload';
import { Notification } from './components/Notification';
import { ChatContainer } from './components/ChatContainer';
import BackgroundPattern from './components/BackgroundPattern/ParticleBackground';
import PDFPreview from './components/PDFPreview/PDFPreview';
import { LoginForm } from './components/LoginForm/LoginForm';

import './styles/global.css';
import styles from './App.module.css';

interface UploadResponse {
    session_id: string;
    message: string;
    initial_analysis: string;
}

const AppContent: React.FC = () => {
    // Use our AuthContext instead of local isAuthenticated state
    const { isAuthenticated } = useAuth();

    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [initialAnalysis, setInitialAnalysis] = useState<string | null>(null);

    const fileUploadRef = useRef(null);
    const contentRef = useRef(null);

    // Called by FileUpload after a successful upload
    const handleUploadSuccess = (files: File[], response: UploadResponse) => {
        setIsFileUploaded(true);
        setUploadedFiles(files);
        setInitialAnalysis(response.initial_analysis);
    };

    // Called by FileUpload if an error occurs
    const handleUploadError = (errorMessage: string) => {
        // If unauthorized, remove token so user is forced to login again

        setError(errorMessage);
    };

    return (
        <div className={styles.app}>
            <BackgroundPattern />
            <Header />
            <main className={styles.mainContent}>
                {!isAuthenticated ? (
                    <LoginForm />
                ) : (
                    <>
                        <CSSTransition
                            nodeRef={fileUploadRef}
                            in={!isFileUploaded}
                            timeout={300}
                            classNames={{
                                enter: styles.fadeEnter,
                                enterActive: styles.fadeEnterActive,
                                exit: styles.fadeExit,
                                exitActive: styles.fadeExitActive,
                            }}
                            unmountOnExit
                        >
                            <div style={{width: '100%'}} ref={fileUploadRef}>
                                <FileUpload
                                    onUploadSuccess={handleUploadSuccess}
                                    onUploadError={handleUploadError}
                                />
                            </div>
                        </CSSTransition>

                        <CSSTransition
                            nodeRef={contentRef}
                            in={isFileUploaded}
                            timeout={300}
                            classNames={{
                                enter: styles.fadeEnter,
                                enterActive: styles.fadeEnterActive,
                                exit: styles.fadeExit,
                                exitActive: styles.fadeExitActive,
                            }}
                            unmountOnExit
                        >
                            <div ref={contentRef} className={styles.contentContainer}>
                                <div className={styles.pdfPreviewWrapper}>
                                    <PDFPreview files={uploadedFiles} />
                                </div>
                                <div className={styles.chatWrapper}>
                                    <ChatContainer initialMessage={initialAnalysis || undefined} onUploadError={handleUploadError} />
                                </div>
                            </div>
                        </CSSTransition>
                    </>
                )}
            </main>
            <Footer />
            {error && (
                <Notification message={error} type="error" onClose={() => setError(null)} />
            )}
        </div>
    );
};

// Wrap AppContent in the AuthProvider and ChatProvider so they’re available
const App: React.FC = () => {
    return (
        <AuthProvider>
            <ChatProvider>
                <AppContent />
            </ChatProvider>
        </AuthProvider>
    );
};

export default App;
